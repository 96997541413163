<template>
  <div class="acoes-grid">
    <b-btn
      v-if="visibleEditar && editar"
      v-b-tooltip.hover
      title="Editar"
      variant="none"
      class="btn btn-none rounded-pill icone-acao"
      style="margin-right:0.3rem"
      @click="editar(info.row)"
    >
      <feather-icon
        icon="Edit2Icon"
        class="text-success"
        size="20"
      />
    </b-btn>

    <b-btn
      v-if="visibleRemover && remover"
      v-b-tooltip.hover
      title="Remover"
      variant="none"
      class="btn btn-none rounded-pill icone-acao"
      @click="remover([info.row])"
    >
      <feather-icon
        icon="TrashIcon"
        class="text-danger"
        size="20"
      />
    </b-btn>
  </div>
</template>

<style lang="scss">
.acoes-grid {
  display: inline-block;
}
.icone-acao {
  padding: 0.3rem;
  // width: 16px;
  // height: 16px;
}
</style>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({

  data() {
    return {
      active: false,
    }
  },

  props: {
    info: {
      type: Object,
      required: true,
    },
    visibleRemover: {
      type: Boolean,
      default: true,
    },
    visibleEditar: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const editar = info => {
      context.emit('editar', info)
    }

    const remover = info => {
      context.emit('remover', info)
    }

    return {
      editar,
      remover,
    }
  },

  methods: {

    show() {
      this.active = !this.active
    },
  },

})
</script>
